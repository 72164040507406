<template>
<b-card title="">

    <b-tabs pills nav-class="mb-md-2">
        <b-tab title="Invoices" active>
            <invoices />
        </b-tab>

    </b-tabs>
</b-card>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormInput,
    BPagination,
    BTab,
    BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'


export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BTable,
        vSelect,
        BFormInput,
        BPagination,
        BTab,
        BTabs,

      

    },
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
