<template>
<div class="">
    <div class="m-2">
        <!-- Table Top -->
        <b-row>
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Entries</label>
                <v-select class="per-page-selector d-inline-block ml-50 mr-1" />

            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                    <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search..." />
                   
                </div>
            </b-col>
        </b-row>
    </div>
    <b-table responsive :borderless="borderless" :hover="hover" :items="items" :fields="fields" class="mb-0">
        <!-- Column: Actions -->
        <template #cell(actions)="">

            <div class="text-nowrap">
                <feather-icon icon="Trash2Icon" size="16" class="mx-1" />
            </div>
        </template>
        <template #cell(Phone)="data">
            <span class="text-nowrap">
                {{ data.value }}
            </span>
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
            {{ data.value }}
        </template>
    </b-table>
    <div class="mx-2 mb-2 mt-1">
        <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">Showing 1 to 10 of 50 entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination class="mb-0 mt-1 mt-sm-0" v-model="currentPage" :total-rows="rows" first-number last-number prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>

            </b-col>

        </b-row>
    </div>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormInput,
    BPagination,
    BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BTable,
        vSelect,
        BFormInput,
        BPagination,
        BButton,
    },
    data() {
        return {
           
            tableVariants: [
                'none',
            ],
            borderless: true,
            hover: true,
        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
