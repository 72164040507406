<template>
<b-card title="Recent Activities" class="recent-activities">
    <div class="recent-activities-inner">
        <app-timeline>
            <!-- 12 INVOICES HAVE BEEN PAID -->
            <app-timeline-item v-for="item in recent_activities" :key="item.order_number" class="mr-1">
                <div class="d-flex flex-sm-row flex-column justify-content-between mb-1 mb-sm-0 ">
                    <h6 class="mr-75">{{item.comment}}</h6>
                    <!-- <h6 v-if='item.item_type=="Product"'>Product purchased</h6>
                <h6 v-if='item.item_type=="Membership"'>Membership purchased</h6> -->
                    <small class="text-muted text-right">{{utctolocaltime(item.payment_date)}}</small>
                </div>
                <!-- <p>{{item.comment}}</p> -->
                <!-- <p class="mb-0">
                <b-img :src="require('@/assets/images/icons/pdf.png')" height="auto" width="20" class="mr-1" />
                <span class="align-bottom">invoice.pdf</span>
            </p> -->
            </app-timeline-item>
            <!-- <app-timeline-item variant="warning">

            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>Registered for upcoming event: {evnt_1}</h6>
                <small class="text-muted">45 min ago</small>
            </div>
            <p>Event to upkill your technicall skills.</p>
            <b-media>
                <template #aside>
                    <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
                </template>
                <h6>Presented by: {Steve Jobs}</h6>
                <p class="mb-0">
                    Ex-CEO of Apple
                </p>
            </b-media>
        </app-timeline-item> -->
            <!-- <app-timeline-item variant="info" title="Signed up as paid member" subtitle="Signed up for {membership_1}" time="2 weeks ago" /> -->
        </app-timeline>
    </div>
</b-card>
</template>

<script>
import {
    BCard,
    BImg,
    BMedia,
    BAvatar,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
    components: {
        BCard,
        BImg,
        BMedia,
        BAvatar,
        AppTimeline,
        AppTimelineItem,
    },
    data() {
        return {
            recent_activities: '',
            user_id:''
        }
    },
    created() {
        this.RecentActivitiesOfUser();
    },
    methods: {
        utctolocaltime(utctime) {

            var date = utctime + " UTC";

            var theDate = new Date(Date.parse(date));

            var year = theDate.getFullYear();
            var month = (1 + theDate.getMonth()).toString();
            var day = theDate.getDate().toString();
            month = month.length > 1 ? month : '0' + month;
            day = day.length > 1 ? day : '0' + day;

            var hours = theDate.getHours().toString();
            // hours = hours.length ==2 ? month : '0' + month;
            var minutes = theDate.getMinutes().toString();

            minutes = minutes.length == 2 ? minutes : '0' + minutes;
            var seconds = theDate.getSeconds().toString();

            seconds = seconds.length == 2 ? seconds : '0' + seconds;
            return day + '-' + month + '-' + year + '  ' + hours + ':' + minutes + ':' + seconds;

        },
        async RecentActivitiesOfUser() {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;

                var myHeaders = new Headers();
                // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                // myHeaders.append('x-api-key', api_key);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
             
                const userData = localStorage.getItem("userdetails")

                if (userData == null || undefined) {
                    this.user_id = null
                } else {
                    this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                }

                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("user_id", this.user_id);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow"
                };

                const rawResponse = await fetch(

                    process.env.VUE_APP_BASE_URL + "recentActivities",
                    requestOptions
                );

                const response = await rawResponse.json();

                this.recent_activities = response.data;

                if (response.status == false) {
                    this.$router.push('/users');
                }
            
        }
    }
}
</script>

<style>

</style>
