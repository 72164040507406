<template>
<div class="benefits">
    <div class='main-loader' v-if='isLoading'></div>

    <section class="pricing">
        <b-container>

            <div class="my-collection" v-if="collections.length != 0">
                <b-row class="match-height mt-1">

                    <b-col cols="6" md="6" lg="3" v-for="(product, index) in collections" :key="product.id">

                        <b-card class="collection-card" no-body>

                            <div class="video-container" v-if='product.product_upload_type=="Video"'>
                                <VideoPlayer :source='product.product_upload_url' />
                            </div>

                            <b-img v-else fluid class="card-img-top product-list-img-object-fit" :src="product.product_image_url" />

                            <div class="icons-container">

                                <!-- Chat Icon -->
                                <div :id="'Chat-'+product.product_id" class="collection-chat-logo" @click='newIconClickHandler(index)'>
                                    <feather-icon size="24" icon="MessageCircleIcon" class="collection-chat-icon" />
                                </div>

                                <!-- Chat Popovers -->
                                <b-popover :target="'Chat-'+ product.product_id" triggers="hover" placement="right">
                                    <p class='filterQuery mb-0' @click='openAIassistant(product,"outfit")'>How would this outfit fit me?</p>

                                </b-popover>
                            </div>

                            <b-card-body class="">

                                <div class="d-flex align-items-center justify-content-between mb-50">
                                  
                                    <feather-icon size="24" icon="HeartIcon" fill="red" class="product-liked" v-if='product.like_or_dislike=="Y"' @click='removeBookMarkedProduct(product,index)' />

                                    <span class="product-price">${{product.previous_price}}</span>

                                </div>
                                <h4 class="card-title product-title mb-0 text-muted text-truncate">{{product.product_name}}</h4>

                                <!-- <b-button class="mt-50" size="sm" @click='navigateToProductDetails(product.product_id)' v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
                                   View Item
                                </b-button> -->
                            </b-card-body>
                            <template #footer>
                                <b-button block class="mt-0 cart-btn" @click='navigateToProductDetails(product.product_id)' v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
                                    <feather-icon icon="ShoppingBagIcon" class="mr-25" />
                                   View Item
                                </b-button>
                            </template>
                        </b-card>
                    </b-col>

                </b-row>
            </div>

            <div class="profile-no-data mt-5 mb-5" v-else>
                <div class="mx-auto text-center">
                    <h2 class="">{{no_benefits}}</h2>
                </div>
            </div>
 
        </b-container>
    </section>

</div>
</template>

<script>
import {
    BCard,
    BCardText,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BPopover,
    VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'



export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BImg,
        BBadge,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BFormSelect,
        vSelect,
        flatPickr,
        // videojs,
        ToastificationContent,
   
        BPopover,
        VBPopover,
    },
     directives: {
    Ripple,
  },

    data() {
        return {

            no_benefits: '',
            collections: '',

            image_url: '',
            productURL: '',

            imageUrl: '',
            isLoading: false,
            secret_key: process.env.VUE_APP_THETTA_SECRET,
            secret_id: process.env.VUE_APP_THETTA_KEY_ID,
            user_id: '',

        }
    },

    created() {
        this.getAllShortlistedProductsByUser();
    },

    methods: {
        openAIassistant(val, val2) {
            this.$store.commit("app/SET_PRODUCT_ID", val.product_id);
            this.$store.commit("app/SET_INITIALIZE_CHAT", true);

            if (val2 == 'outfit') {

                this.$store.commit("app/SET_CHAT_ID", 0);
                this.$store.commit("app/SET_QUERY_TYPE", "fitting");
                this.$store.commit("app/SET_ENQUIRY", Math.random());

            }

        },
        removeBookMarkedProduct(product, index) {
            this.collections.splice(index, 1);
            this.bookmarkProduct(product)
            if (this.collections.length == 0) {
                this.no_benefits = 'Sorry, no items listed here yet! 🙈'
            }
        },
          //Navigate to product Details
        navigateToProductDetails(prId) {
            
                this.$router.push({
                name: "product-details",
                query: {
                    id: prId
                }
            });
        },
        buyProduct(val) {
            window.open(val, '_blank')
        },

        bookmarkProduct(val) {
            this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            this.$store.dispatch('app/bookmarkProduct', {
                user_id: this.user_id,
                product_id: val.product_id,
                updated_by: val.user_id,
                like_or_dislike: 'N'
            })

        },

  
        //Get all benefits purchase of user//
        async getAllShortlistedProductsByUser() {
            const myHeaders = new Headers();
            this.isLoading = true

            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null
            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
            const tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;
            // const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
            // myHeaders.append('x-api-key', api_key);

            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            const urlencoded = new URLSearchParams();
            urlencoded.append("tenant_id", tenant_id);
            urlencoded.append("user_id", this.user_id);
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: urlencoded,
                redirect: "follow"
            };
            const rawResponse = await fetch(
                process.env.VUE_APP_BASE_URL + "userShortlist",
                requestOptions
            );
            const response = await rawResponse.json();
            this.collections = response.data;
            if (response.data.length == 0) {
                this.no_benefits = 'Sorry, no items listed here yet! 🙈'
            }
            this.isLoading = false
            if (response.status == false) {
                this.$router.push('/users');
                this.isLoading = false
            }
        }
    },
}
</script>

<style lang="scss">
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.close-btn {

    cursor: pointer;
    position: absolute;
    top: 3%;
    left: 95%;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    // background-color: #999696;
}

.loader {
    color: #ffffff;
    /* Customize the color of the spinner */
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.product-img-lg {
    width: 100% !important;
    // height: 429px;
    max-height: 429px;
    object-fit: contain;
}

.filterQuery {
    color: black(5, 5, 10);
    cursor: pointer;
}

.round-button {
    border-radius: 50px;
}

@media only screen and (max-width: 600px) {
    main {
        padding: 0 !important;
    }

    .my-collection .col-6 {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}
</style>
