<template>
<div class="">
    <b-card no-body class="my-preferences border-primary">
        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
            <b-badge variant="light-primary">Membership</b-badge>
        </b-card-header>
        <b-card-body>
            <ul class="list-unstyled my-1">
                <li class="d-flex mb-25">
                    <span class="align-middle font-weight-bolder">Account Type:</span>
                    <span class="align-middle ml-50">Creator (Free plan)</span>
                </li>
                <li class="d-flex mb-25">
                    <span class="align-middle font-weight-bolder">Free Credits:</span>
                    <span class="align-middle ml-50">25 requests/month</span>
                </li>
                <li class="d-flex mb-25">
                    <span class="align-middle font-weight-bolder">Cost:</span>
                    <span class="align-middle ml-50">$0</span>
                </li>
                <li class="d-flex">
                    <span class="align-middle font-weight-bolder"> </span>
                    <span class="align-middle ml-50"></span>
                </li>
            </ul>
            <b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click='navigateToProfilePreference'>
                Upgrade Plan
            </b-button>
        </b-card-body>
    </b-card>

</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BFormInput,
    BFormGroup,
    BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import {
    mapState
} from 'vuex';
import {
    FormWizard,
    TabContent
} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BBadge,
        BButton,
        BModal,
        BFormInput,
        BFormGroup,
        BFormRadio,

        vSelect,
        FormWizard,
        TabContent,
        ToastificationContent
    },

  
    computed: {
        user_age() {
            return this.$store.state.app.userAge
        },
        gender() {
            return this.$store.state.app.userGender
        },
        user_height() {
            return this.$store.state.app.userHeight
        },
        user_weight() {
            return this.$store.state.app.userWeight
        }

    },

    methods: {
        navigateToProfilePreference() {
            this.$router.push('/memberships')
        },

    },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style><style lang="scss">
.spaced-item {
    display: inline-block;
    margin-bottom: 0px;
    /* adjust this value to control the spacing */
}

.align-middle {
    /* your styles for vertical alignment or other properties */
}

.demo-inline-spacing>* {
    margin-top: 0.5rem;
}

.profile-preferences {
    box-shadow: none !important;

}

.profile-preferences .wizard-tab-content {
    box-shadow: none !important;

}

.profile-preferences .wizard-card-footer {
    box-shadow: none !important;
}

.profile-preferences.vue-form-wizard .wizard-tab-content {
    padding: 20px 10px 10px;
}

.profile-preferences.vue-form-wizard .wizard-navigation .wizard-nav {
    // padding: 1.42rem 1.42rem 2.14rem 1.42rem;
    padding: 20px 10px 0px;
    border-bottom: 1px solid rgba(34, 41, 47, 0.08);
}
</style>
