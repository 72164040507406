<template>
<div class="">
    <b-row class="match-height">
        <b-col cols="12" xl="9" lg="8" md="7">
            <profile-user-info />
        </b-col>

        <b-col cols="12" md="5" xl="3" lg="4" >
            <profile-preferences />

        </b-col>
    </b-row>

    <b-row class="match-height">
        <b-col cols="12" lg="6">
            <upcoming-events />
        </b-col>
        <b-col cols="12" lg="6">
            <user-timeline />
        </b-col>
    </b-row>

</div>
</template>

<script>
import {
    BRow,
    BCol,
    BAlert,
    BLink,
} from 'bootstrap-vue'

import ProfileUserInfo from './ProfileUserInfo.vue'
import UserPlan from './UserPlan.vue'
import UserTimeline from './UserTimeline.vue'
import UpcomingEvents from './UpcomingEvents.vue'
import ProfilePreferences from './ProfilePreferences.vue'

export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,

        // Local Components
        ProfileUserInfo,
        UserPlan,
        UserTimeline,
        UpcomingEvents,
        ProfilePreferences,
    },
    computed:{
        isFeatured(){
        
            return this.$store.state.app.user_featured
        }
    }

}
</script>
